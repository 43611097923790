const TUI_RUSSIAN_LANGUAGE_ADDON_COMMERCE = {
  cardNumber: ['Номер', 'Номер карты'],
  cardExpiry: ['Срок', 'Срок действия']
};
const TUI_RUSSIAN_LANGUAGE_ADDON_EDITOR = {
  colorSelectorModeNames: ['Сплошной цвет', 'Градиент'],
  toolbarTools: {
    undo: 'Отменить',
    redo: 'Повторить',
    font: 'Шрифт',
    fontStyle: 'Стиль шрифта',
    fontSize: 'Размер шрифта',
    bold: 'Полужирный',
    italic: 'Курсив',
    underline: 'Подчеркнутый',
    strikeThrough: 'Зачеркнутый',
    justify: 'Выравнивание',
    justifyLeft: 'Выровнять текст по левому краю',
    justifyCenter: 'Выровнять текст по центру',
    justifyRight: 'Выровнять текст по правому краю',
    justifyFull: 'Выровнять по ширине',
    list: 'Списки',
    indent: 'Увеличить отступ',
    outdent: 'Уменьшить отступ',
    unorderedList: 'Маркированный список',
    orderedList: 'Нумерованный список',
    quote: 'Цитата',
    foreColor: 'Цвет: Текст',
    hiliteColor: 'Цвет выделения',
    backColor: 'Цвет фона',
    clear: 'Сбросить форматирование',
    link: 'Ссылка',
    attach: 'Вставить файл',
    tex: 'Вставить формулу',
    code: 'Code',
    image: 'Вставить изображение',
    insertHorizontalRule: 'Разделитель',
    superscript: 'Надстрочный',
    subscript: 'Подстрочный',
    insertTable: 'Добавить таблицу',
    insertGroup: 'Добавить группу',
    hiliteGroup: 'Покрасить группу',
    removeGroup: 'Удалить группу',
    insertAnchor: 'Добавить якорь',
    mergeCells: 'Объединить ячейки',
    splitCells: 'Разъединить ячейки',
    rowsColumnsManaging: 'Управление строками и столбцами',
    cellColor: 'Цвет ячейки',
    setDetails: 'Аккордеон',
    removeDetails: 'Удалить аккордеон'
  },
  editorEditLink: {
    urlExample: 'example.com',
    anchorExample: 'якорь'
  },
  editorTableCommands: [['Добавить столбец слева', 'Добавить столбец справа'], ['Добавить строку сверху', 'Добавить строку снизу'], ['Удалить столбец', 'Удалить строку']],
  editorCodeOptions: ['Код', 'Код в блоке'],
  editorFontOptions: {
    small: 'Мелкий',
    normal: 'Обычный',
    large: 'Крупный',
    title: 'Заголовок',
    subtitle: 'Подзаголовок'
  }
};
const TUI_RUSSIAN_LANGUAGE_ADDON_TABLE = {
  showHideText: 'Показать/Скрыть',
  paginationTexts: {
    pages: 'Страниц',
    linesPerPage: 'Строк на страницу',
    of: 'из'
  }
};
const TUI_RUSSIAN_LANGUAGE_COUNTRIES = {
  AD: 'Андорра',
  AE: 'Объединенные Арабские Эмираты',
  AF: 'Афганистан',
  AG: 'Антигуа и Барбуда',
  AI: 'Ангилья',
  AL: 'Албания',
  AM: 'Армения',
  AO: 'Ангола',
  AR: 'Аргентина',
  AT: 'Австрия',
  AU: 'Австралия',
  AW: 'Аруба',
  AZ: 'Азербайджан',
  BA: 'Босния и Герцеговина',
  BB: 'Барбадос',
  BD: 'Бангладеш',
  BE: 'Бельгия',
  BF: 'Буркина Фасо',
  BG: 'Болгария',
  BH: 'Бахрейн',
  BI: 'Бурунди',
  BJ: 'Бенин',
  BL: 'Сен-Бартельми',
  BM: 'Бермудские острова',
  BN: 'Бруней-Даруссалам',
  BO: 'Боливия',
  BQ: 'Бонайре, Синт-Эстатиус и Саба',
  BR: 'Бразилия',
  BS: 'Багамские Острова',
  BT: 'Бутан',
  BW: 'Ботсвана',
  BY: 'Беларусь (Белоруссия)',
  BZ: 'Белиз',
  CA: 'Канада',
  CD: 'Дем. Респ. Конго (Киншаса)',
  CF: 'Центральноафриканская Республика',
  CG: 'Конго (Браззавиль)',
  CH: 'Швейцария',
  CI: 'Кот-д’Ивуар',
  CL: 'Чили',
  CM: 'Камерун',
  CN: 'Китайская Н.Р.',
  CO: 'Колумбия',
  CR: 'Коста-Рика',
  CU: 'Куба',
  CV: 'Кабо-Верде',
  CW: 'Кюрасао',
  CY: 'Кипр',
  CZ: 'Чехия',
  DE: 'Германия',
  DJ: 'Джибути',
  DK: 'Дания',
  DM: 'Доминика',
  DO: 'Доминиканская Республика',
  DZ: 'Алжир',
  EC: 'Эквадор ',
  EE: 'Эстония ',
  EG: 'Египет',
  ER: 'Эритрея',
  ES: 'Испания',
  ET: 'Эфиопия',
  FI: 'Финляндия',
  FJ: 'Фиджи',
  FK: 'Фолклендские острова',
  FM: 'Ф.Ш. Микронезии',
  FR: 'Франция',
  GA: 'Габон',
  GB: 'Великобритания',
  GD: 'Гренада',
  GE: 'Грузия',
  GF: 'Гайана',
  GH: 'Гана',
  GI: 'Гибралтар',
  GL: 'Гренландия',
  GM: 'Гамбия',
  GN: 'Гвинея',
  GP: 'Гваделупа',
  GQ: 'Экваториальная Гвинея',
  GR: 'Греция',
  GT: 'Гватемала',
  GW: 'Гвинея-Бисау',
  GY: 'Гайана',
  HK: 'Гонконг',
  HN: 'Гондурас',
  HR: 'Хорватия',
  HT: 'Гаити',
  HU: 'Венгрия',
  ID: 'Индонезия ',
  IE: 'Ирландия',
  IL: 'Израиль',
  IN: 'Индия',
  IQ: 'Ирак',
  IR: 'Иран',
  IS: 'Исландия',
  IT: 'Италия',
  JM: 'Ямайка',
  JO: 'Иордания',
  JP: 'Япония ',
  KE: 'Кения',
  KG: 'Киргизия',
  KH: 'Камбоджа',
  KM: 'Коморы',
  KN: 'Сент-Китс и Невис',
  KP: 'Корейская НДР',
  KR: 'Респ. Корея',
  KW: 'Кувейт',
  KY: 'Каймановы острова',
  KZ: 'Казахстан',
  LA: 'Лаос',
  LB: 'Ливан ',
  LC: 'Сент-Люсия',
  LI: 'Лихтенштейн',
  LK: 'Шри-Ланка',
  LR: 'Либерия',
  LS: 'Лесото',
  LT: 'Литва',
  LU: 'Люксембург',
  LV: 'Латвия',
  LY: 'Ливия',
  MA: 'Марокко',
  MC: 'Монако',
  MD: 'Молдова',
  ME: 'Черногория',
  MF: 'Сен-Мартен',
  MG: 'Мадагаскар',
  MK: 'Респ. Северная Македония',
  ML: 'Мали',
  MM: 'Бирма (Мьянма)',
  MN: 'Монголия',
  MO: 'Макао',
  MQ: 'Мартиника',
  MR: 'Мавритания',
  MS: 'Монтсеррат',
  MT: 'Мальта',
  MU: 'Маврикий',
  MV: 'Мальдивские острова',
  MW: 'Малави',
  MX: 'Мексика',
  MY: 'Малайзия',
  MZ: 'Мозамбик',
  NA: 'Намибия',
  NC: 'Новая Каледония',
  NE: 'Нигер',
  NG: 'Нигерия',
  NI: 'Никарагуа',
  NL: 'Нидерланды',
  NO: 'Норвегия',
  NP: 'Непал',
  NZ: 'Новая Зеландия',
  OM: 'Оман',
  PA: 'Панама',
  PE: 'Перу',
  PF: 'Французская Полинезия (Таити)',
  PG: 'Папуа-Новая Гвинея',
  PH: 'Филиппины',
  PK: 'Пакистан',
  PL: 'Польша',
  PT: 'Португалия',
  PW: 'Палау',
  PY: 'Парагвай',
  QA: 'Катар',
  RE: 'Реюньон',
  RO: 'Румыния',
  RS: 'Сербия',
  RU: 'Россия',
  RW: 'Руанда',
  SA: 'Саудовская Аравия ',
  SB: 'Соломоновы Острова ',
  SC: 'Сейшелы',
  SD: 'Судан',
  SE: 'Швеция',
  SG: 'Сингапур',
  SH: 'Остров Святой Елены',
  SI: 'Словения',
  SK: 'Словакия',
  SL: 'Сьерра-Леоне',
  SM: 'Сан-Марино',
  SN: 'Сенегал',
  SO: 'Сомали',
  SR: 'Суринам ',
  ST: 'Сан-Томе и Принсипи',
  SV: 'Сальвадор',
  SX: 'Синт-Мартен',
  SY: 'Сирийская арабская республика',
  SZ: 'Свазиленд',
  TC: 'Тёркс и Кайкос',
  TD: 'Чад',
  TG: 'Того',
  TH: 'Таиланд ',
  TJ: 'Таджикистан',
  TL: 'Восточный Тимор',
  TM: 'Туркменистан',
  TN: 'Тунис',
  TO: 'Тонга',
  TR: 'Турция',
  TT: 'Тринидад и Тобаго',
  TW: 'Тайвань',
  TZ: 'Танзания',
  UA: 'Украина',
  UG: 'Уганда',
  US: 'США',
  UY: 'Уругвай',
  UZ: 'Узбекистан',
  VC: 'Сент-Винсент и Гренадины',
  VE: 'Венесуэла',
  VG: 'Британские Виргинские острова',
  VN: 'Вьетнам',
  VU: 'Вануату ',
  WS: 'Самоа',
  YE: 'Йемен ',
  YT: 'Майотта',
  ZA: 'Южно-Африканская Респ.',
  ZM: 'Замбия',
  ZW: 'Зимбабве',
  XK: 'Косово',
  AC: 'Остров Асенсьон',
  AS: 'Американское Самоа',
  AX: 'Аландские острова',
  CC: 'Кокосовые (Килинг) острова',
  CK: 'Острова Кука',
  CX: 'Остров Рождества',
  EH: 'Западная Сахара',
  FO: 'Фарерские острова',
  GG: 'Гернси',
  GU: 'Гуам',
  IM: 'Остров Мэн',
  JE: 'Джерси',
  IO: 'Британская Территория в Индийском Океане',
  KI: 'Кирибати',
  MH: 'Маршалловы Острова',
  MP: 'Северные Марианские Острова',
  NF: 'Остров Норфолк',
  NR: 'Науру',
  NU: 'Ниуэ',
  PM: 'Сен-Пьер и Микелон',
  PR: 'Пуэрто-Рико',
  PS: 'Палестина',
  SJ: 'Шпицберген и Ян-Майен',
  SS: 'Южный Судан',
  TA: 'Тристан-да-Кунья',
  TK: 'Токелау',
  TV: 'Тувалу',
  VA: 'Святой Престол (Ватикан)',
  VI: 'Виргинские острова',
  WF: 'Острова Уоллис и Футуна'
};
const TUI_RUSSIAN_LANGUAGE_CORE = {
  months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
  close: 'Закрыть',
  clear: 'Очистить',
  nothingFoundMessage: 'Ничего не найдено',
  defaultErrorMessage: 'Поле заполнено неверно',
  spinTexts: ['Предыдущий', 'Следующий'],
  shortWeekDays: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
  countries: TUI_RUSSIAN_LANGUAGE_COUNTRIES
};
const TUI_RUSSIAN_LANGUAGE_KIT = {
  cancel: 'Отменить',
  done: 'Готово',
  more: 'Еще',
  otherDate: 'Другая дата...',
  showAll: 'Показать все',
  hide: 'Скрыть',
  mobileCalendarTexts: ['Выберите день', 'Выберите период', 'Выбрать дни'],
  range: ['от', 'до'],
  countTexts: ['Плюс', 'Минус'],
  time: {
    'HH:MM': 'ЧЧ:ММ',
    'HH:MM AA': 'ЧЧ:ММ AA',
    'HH:MM:SS': 'ЧЧ:ММ:СС',
    'HH:MM:SS AA': 'ЧЧ:ММ:СС AA',
    'HH:MM:SS.MSS': 'ЧЧ:ММ:СС.МСС',
    'HH:MM:SS.MSS AA': 'ЧЧ:ММ:СС.МСС AA'
  },
  dateTexts: {
    DMY: 'дд.мм.гггг',
    MDY: 'мм.дд.гггг',
    YMD: 'гггг.мм.дд'
  },
  digitalInformationUnits: ['Б', 'КиБ', 'МиБ'],
  passwordTexts: ['Показать пароль', 'Скрыть пароль'],
  copyTexts: ['Копировать', 'Скопировано'],
  shortCalendarMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Нояб', 'Дек'],
  pagination: ['Предыдущая страница', 'Следующая страница'],
  fileTexts: {
    loadingError: 'Ошибка загрузки',
    preview: 'Предварительный просмотр',
    remove: 'Удалить'
  },
  inputFileTexts: {
    defaultLabelSingle: 'или перетащите\u00A0его\u00A0сюда',
    defaultLabelMultiple: 'или перетащите\u00A0их\u00A0сюда',
    defaultLinkSingle: 'Выберите файл',
    defaultLinkMultiple: 'Выберите файлы',
    maxSizeRejectionReason: 'Файл превышает объем',
    formatRejectionReason: 'Неверный формат файла',
    drop: 'Поместите файл сюда',
    dropMultiple: 'Поместите файлы сюда'
  },
  multiSelectTexts: {
    all: 'Выбрать все',
    none: 'Снять выбор'
  },
  confirm: {
    yes: 'Да',
    no: 'Нет'
  },
  previewTexts: {
    rotate: 'Повернуть'
  },
  zoomTexts: {
    zoomIn: 'Увеличить масштаб',
    zoomOut: 'Уменьшить масштаб',
    reset: 'Сбросить масштаб'
  },
  phoneSearch: 'Введите страну или код'
};
const TUI_RUSSIAN_LANGUAGE = {
  name: 'russian',
  ...TUI_RUSSIAN_LANGUAGE_CORE,
  ...TUI_RUSSIAN_LANGUAGE_KIT,
  ...TUI_RUSSIAN_LANGUAGE_ADDON_COMMERCE,
  ...TUI_RUSSIAN_LANGUAGE_ADDON_TABLE,
  ...TUI_RUSSIAN_LANGUAGE_ADDON_EDITOR
};

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_RUSSIAN_LANGUAGE, TUI_RUSSIAN_LANGUAGE_ADDON_COMMERCE, TUI_RUSSIAN_LANGUAGE_ADDON_EDITOR, TUI_RUSSIAN_LANGUAGE_ADDON_TABLE, TUI_RUSSIAN_LANGUAGE_CORE, TUI_RUSSIAN_LANGUAGE_COUNTRIES, TUI_RUSSIAN_LANGUAGE_KIT };
