import * as i0 from '@angular/core';
import { inject, NgZone, Injectable, Directive, Output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TUI_TRUE_HANDLER, TUI_FALSE_HANDLER } from '@taiga-ui/cdk/constants';
import { tuiTypedFromEvent, tuiZoneOptimized, tuiWatch } from '@taiga-ui/cdk/observables';
import { TUI_IS_MOBILE } from '@taiga-ui/cdk/tokens';
import { tuiIsElement, tuiInjectElement } from '@taiga-ui/cdk/utils';
import { Observable, merge, map, filter, distinctUntilChanged, of } from 'rxjs';
function movedOut({
  currentTarget,
  relatedTarget
}) {
  return !tuiIsElement(relatedTarget) || !tuiIsElement(currentTarget) || !currentTarget.contains(relatedTarget);
}
class TuiHoveredService extends Observable {
  constructor() {
    super(subscriber => this.stream$.subscribe(subscriber));
    this.el = tuiInjectElement();
    this.zone = inject(NgZone);
    this.stream$ = merge(tuiTypedFromEvent(this.el, 'mouseenter').pipe(map(TUI_TRUE_HANDLER)), tuiTypedFromEvent(this.el, 'mouseleave').pipe(map(TUI_FALSE_HANDLER)),
    // Hello, Safari
    tuiTypedFromEvent(this.el, 'mouseout').pipe(filter(movedOut), map(TUI_FALSE_HANDLER))).pipe(distinctUntilChanged(), tuiZoneOptimized(this.zone));
  }
  static {
    this.ɵfac = function TuiHoveredService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiHoveredService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TuiHoveredService,
      factory: TuiHoveredService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHoveredService, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();
function tuiHovered() {
  return toSignal(inject(TUI_IS_MOBILE) ? of(false) : inject(TuiHoveredService).pipe(tuiWatch()), {
    initialValue: false
  });
}
class TuiHovered {
  constructor() {
    this.tuiHoveredChange = inject(TuiHoveredService);
  }
  static {
    this.ɵfac = function TuiHovered_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiHovered)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiHovered,
      selectors: [["", "tuiHoveredChange", ""]],
      outputs: {
        tuiHoveredChange: "tuiHoveredChange"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([TuiHoveredService])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHovered, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiHoveredChange]',
      providers: [TuiHoveredService]
    }]
  }], null, {
    tuiHoveredChange: [{
      type: Output
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiHovered, TuiHoveredService, tuiHovered };
