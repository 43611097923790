import * as i0 from '@angular/core';
import { inject, Directive } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WA_WINDOW } from '@ng-web-apis/common';
import { ViewportService } from '@ng-web-apis/screen-orientation';
import { tuiInjectElement, tuiPx } from '@taiga-ui/cdk/utils';
class TuiVisualViewport {
  constructor() {
    this.w = inject(WA_WINDOW);
    this.style = tuiInjectElement().style;
    this.$ = inject(ViewportService).pipe(takeUntilDestroyed()).subscribe(({
      offsetLeft,
      offsetTop,
      height,
      width,
      scale
    }) => {
      this.style.setProperty('--tui-viewport-x', tuiPx(offsetLeft));
      this.style.setProperty('--tui-viewport-y', tuiPx(offsetTop));
      this.style.setProperty('--tui-viewport-height', tuiPx(height));
      this.style.setProperty('--tui-viewport-width', tuiPx(width));
      this.style.setProperty('--tui-viewport-scale', String(scale));
      this.style.setProperty('--tui-viewport-vh', tuiPx(this.w.innerHeight / 100));
      this.style.setProperty('--tui-viewport-vw', tuiPx(this.w.innerWidth / 100));
    });
  }
  static {
    this.ɵfac = function TuiVisualViewport_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiVisualViewport)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiVisualViewport,
      selectors: [["", "tuiVisualViewport", ""]],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiVisualViewport, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiVisualViewport]'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiVisualViewport };
