import * as i0 from '@angular/core';
import { Injectable, inject, TemplateRef, Directive, Input } from '@angular/core';
import { TuiPortalService } from '@taiga-ui/cdk/classes';
class TuiPopupService extends TuiPortalService {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiPopupService_BaseFactory;
      return function TuiPopupService_Factory(__ngFactoryType__) {
        return (ɵTuiPopupService_BaseFactory || (ɵTuiPopupService_BaseFactory = i0.ɵɵgetInheritedFactory(TuiPopupService)))(__ngFactoryType__ || TuiPopupService);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TuiPopupService,
      factory: TuiPopupService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPopupService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class TuiPopup {
  constructor() {
    this.template = inject(TemplateRef);
    this.service = inject(TuiPopupService);
  }
  set tuiPopup(show) {
    this.viewRef?.destroy();
    if (show) {
      this.viewRef = this.service.addTemplate(this.template);
    }
  }
  ngOnDestroy() {
    this.viewRef?.destroy();
  }
  static {
    this.ɵfac = function TuiPopup_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiPopup)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiPopup,
      selectors: [["ng-template", "tuiPopup", ""]],
      inputs: {
        tuiPopup: "tuiPopup"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPopup, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: 'ng-template[tuiPopup]'
    }]
  }], null, {
    tuiPopup: [{
      type: Input
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiPopup, TuiPopupService };
