import * as i0 from '@angular/core';
import { inject, Directive } from '@angular/core';
import { tuiIfMap } from '@taiga-ui/cdk/observables';
import { TuiPopoverService } from '@taiga-ui/cdk/services';
import { PolymorpheusTemplate } from '@taiga-ui/polymorpheus';
import { Subject, distinctUntilChanged, ignoreElements, endWith, share } from 'rxjs';
class TuiPopoverDirective extends PolymorpheusTemplate {
  constructor() {
    super(...arguments);
    this.service = inject(TuiPopoverService);
    this.open$ = new Subject();
    this.options = {};
    this.open = false;
    this.openChange = this.open$.pipe(distinctUntilChanged(), tuiIfMap(() => this.service.open(this, this.options).pipe(ignoreElements(), endWith(false))), share());
  }
  ngOnChanges() {
    this.open$.next(this.open);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiPopoverDirective_BaseFactory;
      return function TuiPopoverDirective_Factory(__ngFactoryType__) {
        return (ɵTuiPopoverDirective_BaseFactory || (ɵTuiPopoverDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiPopoverDirective)))(__ngFactoryType__ || TuiPopoverDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiPopoverDirective,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPopoverDirective, [{
    type: Directive
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiPopoverDirective };
