import * as i0 from '@angular/core';
import { inject, Injectable } from '@angular/core';
import { WA_WINDOW, WINDOW } from '@ng-web-apis/common';
import { Observable, fromEvent, startWith, map, shareReplay, merge, filter, EMPTY } from 'rxjs';
class ScreenOrientationService extends Observable {
  win = inject(WA_WINDOW);
  stream$ = (this.isModern ? fromEvent(this.win.screen.orientation, 'change').pipe(startWith(null), map(() =>
  /**
   * https://developer.mozilla.org/en-US/docs/Web/API/Screen/orientation
   * The type read-only property of the ScreenOrientation interface returns the document's current orientation type,
   * one of "portrait-primary", "portrait-secondary", "landscape-primary", or "landscape-secondary".
   *
   * Browser compatibility:
   * Safari 16.4+, Chrome 38+, Firefox 43+
   */
  this.win.screen.orientation.type)) : fromEvent(this.win, 'orientationchange').pipe(startWith(null), map(() => {
    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/Window/orientation
     * Its only possible values are -90, 0, 90, and 180.
     * Positive values are counterclockwise; negative values are clockwise.
     */
    const angle = parseInt(this.win.orientation, 10);
    switch (angle) {
      case -90:
        return 'landscape-secondary';
      case 180:
        return 'portrait-secondary';
      case 90:
        return 'landscape-primary';
      case 0:
      default:
        return 'portrait-primary';
    }
  }))).pipe(shareReplay({
    bufferSize: 1,
    refCount: true
  }));
  constructor() {
    super(subscriber => this.stream$.subscribe(subscriber));
  }
  get isModern() {
    return !!this.win?.screen?.orientation;
  }
  static ɵfac = function ScreenOrientationService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ScreenOrientationService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ScreenOrientationService,
    factory: ScreenOrientationService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScreenOrientationService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class ViewportService extends Observable {
  visualViewport = inject(WINDOW).visualViewport;
  stream$ = this.visualViewport ? merge(fromEvent(this.visualViewport, 'resize'), fromEvent(this.visualViewport, 'scroll'), fromEvent(this.visualViewport, 'scrollend')).pipe(startWith(null), map(() => this.visualViewport), filter(Boolean), shareReplay({
    bufferSize: 1,
    refCount: true
  })) : EMPTY;
  constructor() {
    super(subscriber => this.stream$.subscribe(subscriber));
  }
  static ɵfac = function ViewportService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ViewportService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ViewportService,
    factory: ViewportService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ViewportService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ScreenOrientationService, ViewportService };
